import { navigate } from "gatsby";
import React, { useState } from "react";
import { Mail, MessageSquare, Phone, Send, User } from "react-feather";

export const Contact = () => {
  const [form, setForm] = useState({});
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join("&");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const target = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      // eslint-disable-next-line no-undef
      body: encode({
        "form-name": target.getAttribute("name"),
        ...form,
      }),
    })
      // eslint-disable-next-line no-undef
      .then(() => navigate(target.getAttribute("action")))
      .catch(console.error);
  };
  return (
    <div className="w-full py-4 mx-auto xs:px-4 md:px-12 xs:bg-gray-800 lg:bg-transparent">
      <h2 className="pl-1 text-3xl font-extrabold leading-9 uppercase md:pl-11 font-logo text-secondary-700 q-stroke-primary sm:text-4xl sm:leading-10">
        Contact
      </h2>

      <form
        className="flex flex-col my-4"
        name="contact"
        id="contact"
        action="/bedankt"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden={true}>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div className="flex w-full">
          <div className="flex content-center justify-center h-full p-2 text-secondary-700">
            <User />
          </div>
          <div className="w-full text-gray-900 q-floating-label">
            <input
              className="w-full p-2 text-sm border-transparent outline-none"
              type="text"
              name="name"
              id="name"
              placeholder="Naam"
              onChange={handleChange}
            />
            <label htmlFor="name">Naam</label>
          </div>
        </div>
        <div className="flex w-full xs:flex-col">
          <div className="flex w-full">
            <div className="flex content-center justify-center h-full p-2 text-secondary-700">
              <Phone />
            </div>
            <div className="w-full text-gray-900 q-floating-label">
              <input
                className="w-full p-2 text-sm border-transparent outline-none"
                type="text"
                name="phone"
                id="phone"
                placeholder="Telefoonnummer"
                onChange={handleChange}
              />
              <label htmlFor="phone">Telefoonnummer</label>
            </div>
          </div>
          <div className="flex w-full">
            <div className="flex content-center justify-center h-full p-2 text-secondary-700">
              <Mail />
            </div>
            <div className="w-full text-gray-900 q-floating-label">
              <input
                className="w-full p-2 text-sm border-transparent outline-none"
                type="text"
                name="mail"
                id="mail"
                placeholder="E-mailadres"
                onChange={handleChange}
              />
              <label htmlFor="mail">E-mailadres</label>
            </div>
          </div>
        </div>

        <div className="flex mb-2">
          <div className="flex content-center justify-center h-full p-2 text-secondary-700">
            <MessageSquare />
          </div>
          <div className="w-full text-gray-900 q-floating-label">
            <textarea
              className="w-full p-2 text-sm border-transparent outline-none"
              type="text"
              name="message"
              id="message"
              placeholder="Bericht"
              onChange={handleChange}
            ></textarea>

            <label htmlFor="message">Bericht</label>
          </div>
        </div>
        <div className="flex flex-row-reverse content-center justify-between md:pl-10">
          <button
            className="flex content-center justify-center w-full px-3 py-4 text-white bg-secondary-700"
            name="submit"
            value="Versturen"
          >
            <span className="pr-2 font-semibold">Versturen</span>
            <Send className="text-primary-500" />
          </button>
        </div>
      </form>
    </div>
  );
};
