import React from "react";

function SvgToolsWenchHold(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M21.129 24.001a.743.743 0 01-.53-.22l-4.19-4.189a2.254 2.254 0 010-3.183 1.994 1.994 0 000-2.817l-2.405-2.405-1.565 1.565.348.348a3.756 3.756 0 010 5.303l-2.507 2.508a3.777 3.777 0 01-.979.703l1.108 1.108a.75.75 0 11-1.06 1.061l-1.776-1.775a3.727 3.727 0 01-2.597-1.096l-.348-.348-3.218 3.217a.744.744 0 01-1.06 0 .752.752 0 010-1.061l3.219-3.219-.659-.659a2.254 2.254 0 010-3.183l4.629-4.628a2.231 2.231 0 011.846-.642 3.22 3.22 0 01.646-1.84c.015-.019.028-.036.042-.051a2.24 2.24 0 011.686-.75 2.25 2.25 0 011.679.753l.566.565.672-.672a5.2 5.2 0 01-.746-1.393c-.467-1.32-.391-2.745.214-4.012S15.809.768 17.129.301A5.213 5.213 0 0118.875 0a5.262 5.262 0 014.954 3.5.75.75 0 01-.707 1H20.38a.75.75 0 000 1.5h2.742a.749.749 0 01.707 1 5.224 5.224 0 01-3.2 3.2 5.202 5.202 0 01-4.892-.747l-.673.673 2.405 2.405A3.466 3.466 0 0118.49 15c0 .934-.363 1.811-1.021 2.469a.744.744 0 00-.219.531c0 .201.078.389.219.53l4.19 4.19a.752.752 0 01-.53 1.281zM6.038 19.85c.424.424.99.658 1.592.658.602 0 1.167-.233 1.591-.657l2.507-2.508a2.253 2.253 0 000-3.182L9.66 12.093c-.141-.141-.33-.219-.53-.219s-.389.078-.53.219L3.97 16.72a.746.746 0 00-.219.531c0 .201.078.389.219.53l1.204 1.204.864.865zm5.341-8.159l1.565-1.565-.595-.595a1.054 1.054 0 00-.088-.089.753.753 0 00-.501-.193l-.046.001a.76.76 0 00-.507.23 1.754 1.754 0 00-.001 2.039l.173.172zm7.496-10.19a3.755 3.755 0 00-3.532 5.001 3.727 3.727 0 001.919 2.134 3.72 3.72 0 002.866.151 3.727 3.727 0 001.752-1.286h-1.502c-1.241 0-2.25-1.009-2.25-2.25s1.009-2.25 2.25-2.25h1.499a3.79 3.79 0 00-3.002-1.5z" />
    </svg>
  );
}

export default SvgToolsWenchHold;
