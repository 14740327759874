import '../css/style.css';

import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import { Contact } from '../components/contact';
import { CTA } from '../components/cta';
import { Hero1 } from '../components/hero1';
import SEO from '../components/seo';
import { Services } from '../components/services';

function Index({ data }) {
  const ctaImage = (() => {
    const filename = data.contact.frontmatter.image.replace("/images/uploads/", "");
    const edge = data.images.edges.filter(edge => {
      return edge.node.fluid.originalName ===filename
    })[0]
    return edge.node.fluid;
  })();
  return (
    <div>
      <SEO />
      <Helmet>
        <body className="font-sans antialiased bg-white" />
      </Helmet>
      <Hero1 />
      <div className="bg-secondary-700">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div>
              <h2 className="text-3xl font-extrabold leading-9 text-gray-200 uppercase font-logo q-stroke-secondary sm:text-4xl sm:leading-10">
                {data.page.frontmatter.services_title}
              </h2>
              <p className="max-w-3xl mt-3 text-lg leading-7 text-gray-300">
                {data.page.frontmatter.services_description}
              </p>
            </div>
            <Services />
          </div>
        </div>
      </div>
      <CTA image={ctaImage}>
        <Contact />
      </CTA>
    </div>
  );
}

export const query = graphql`
query Frontpage {
  page: markdownRemark(fileAbsolutePath: {regex: "/index.md/"}) {
    frontmatter {
      services_title
      services_description
    }
  }
  contact: markdownRemark(fileAbsolutePath:{regex:"/contact.md/"}) {
    frontmatter{
      image
      title
    }
  }
  images:allImageSharp {
    edges {
      node {
        fluid {
          srcSet
          originalName
        }
      }
    }
  }
}
`

export default Index;
