import React from "react";

function SvgToolsHammerHold(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.998 24a.743.743 0 01-.53-.22l-4.189-4.189a2.254 2.254 0 010-3.183c.375-.375.582-.876.582-1.409s-.207-1.033-.582-1.409l-2.406-2.406-1.564 1.564.349.349a3.756 3.756 0 010 5.303l-2.508 2.508a3.743 3.743 0 01-.979.703l1.108 1.108a.744.744 0 010 1.06c-.142.143-.33.221-.531.221s-.389-.078-.53-.22l-1.775-1.775a3.723 3.723 0 01-2.596-1.096l-.349-.349-3.22 3.22c-.141.142-.329.22-.53.22s-.389-.078-.53-.22a.744.744 0 010-1.06l3.22-3.22-.659-.659a2.253 2.253 0 010-3.183l4.628-4.628a2.231 2.231 0 011.846-.642 3.22 3.22 0 01.646-1.84c.015-.019.028-.036.042-.051a2.264 2.264 0 013.366.003l.566.565.686-.686L12.4 6.22a2.253 2.253 0 010-3.182L14.779.659C15.204.234 15.768 0 16.37 0s1.166.234 1.591.659l5.379 5.379a2.253 2.253 0 010 3.182l-2.379 2.379a2.235 2.235 0 01-1.591.658 2.238 2.238 0 01-1.591-.658L15.62 9.44l-.686.686 2.406 2.405A3.466 3.466 0 0118.361 15c0 .934-.363 1.811-1.021 2.469a.751.751 0 00-.001 1.061l4.19 4.19a.748.748 0 01-.531 1.28zm-12-12.128a.744.744 0 00-.53.219L3.84 16.719a.75.75 0 000 1.061l2.068 2.069c.424.424.989.657 1.591.657s1.167-.233 1.591-.657l2.508-2.508a2.253 2.253 0 000-3.182L9.53 12.091a.75.75 0 00-.532-.219zm2.251-.183l1.564-1.564-.595-.595a1.054 1.054 0 00-.088-.089.761.761 0 00-1.053.039 1.755 1.755 0 00-.002 2.036l.174.173zM16.37 1.5c-.2 0-.389.078-.531.22L13.46 4.098a.752.752 0 000 1.061l5.379 5.379c.141.141.33.219.531.219s.389-.078.53-.219l2.379-2.379a.752.752 0 000-1.061L16.9 1.72a.744.744 0 00-.53-.22z" />
    </svg>
  );
}

export default SvgToolsHammerHold;
