import React from "react";

function SvgDesktopMonitorApprove(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M17.25 13.5c-3.722 0-6.75-3.028-6.75-6.75S13.528 0 17.25 0a.75.75 0 010 1.5C14.355 1.5 12 3.855 12 6.75S14.355 12 17.25 12s5.25-2.355 5.25-5.25a.75.75 0 011.5 0 6.758 6.758 0 01-6.75 6.75z" />
      <path d="M17.25 8.689c-.4 0-.776-.156-1.059-.438l-1.721-1.72a.744.744 0 010-1.06.748.748 0 011.06-.002l1.72 1.719 5.47-5.468a.744.744 0 011.06 0c.142.141.22.33.22.53s-.078.389-.22.53l-5.47 5.469c-.282.283-.659.44-1.06.44.001 0 0 0 0 0zM6 24a.75.75 0 010-1.5h1.664l.375-1.5H3.75A3.754 3.754 0 010 17.25V6.75A3.754 3.754 0 013.75 3h4.5a.75.75 0 010 1.5h-4.5A2.252 2.252 0 001.5 6.75v9.75h21v-2.25a.75.75 0 011.5 0v3A3.754 3.754 0 0120.25 21h-4.29l.375 1.5H18a.75.75 0 010 1.5H6zm8.79-1.5l-.375-1.5H9.586l-.375 1.5h5.579zM1.632 18a2.262 2.262 0 002.118 1.5h16.5c.96 0 1.805-.621 2.118-1.5H1.632z" />
    </svg>
  );
}

export default SvgDesktopMonitorApprove;
