import React from "react";

function SvgCeilingLamp(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 19.5a3.743 3.743 0 01-3.673-3H4.54a1.481 1.481 0 01-1.13-.505 1.483 1.483 0 01-.371-1.082 9.01 9.01 0 015.212-7.339V6.75c0-.209.018-.42.052-.626a3.767 3.767 0 012.947-3.046V.75a.75.75 0 011.5 0v2.329a3.865 3.865 0 013 3.849v.647a8.99 8.99 0 015.209 7.313 1.51 1.51 0 01-1.504 1.612h-3.782a3.743 3.743 0 01-3.673 3zm-2.118-3c.313.879 1.158 1.5 2.118 1.5s1.805-.621 2.118-1.5H9.882zM19.46 15l.003-.004a7.458 7.458 0 00-4.736-6.226.747.747 0 01-.477-.699v-1.16a2.368 2.368 0 00-1.897-2.385 2.23 2.23 0 00-1.656.389A2.233 2.233 0 009.75 6.75v1.321a.747.747 0 01-.477.699A7.467 7.467 0 004.537 15H19.46zM12 24a.75.75 0 01-.75-.75v-1.5a.75.75 0 011.5 0v1.5A.75.75 0 0112 24zM18.75 23.25a.749.749 0 01-.624-.334l-1.5-2.25a.748.748 0 01.624-1.166c.251 0 .485.125.624.334l1.5 2.25a.748.748 0 01-.624 1.166zM5.25 23.25a.75.75 0 01-.624-1.166l1.5-2.25a.747.747 0 011.04-.208.751.751 0 01.208 1.04l-1.5 2.25a.749.749 0 01-.624.334z" />
    </svg>
  );
}

export default SvgCeilingLamp;
