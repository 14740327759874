import Img from 'gatsby-image';
import React from 'react';

export const CTA = ({ children, image }) => {
  return (
    <div className="relative bg-primary-500">
      <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img
          className="object-cover w-full h-full"
          fluid={image}
          alt="Contact"
        />
        <svg
          className="absolute inset-y-0 right-0 hidden w-48 h-full transform translate-x-1/2 text-primary-500 lg:block"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
      </div>
      <div className="relative max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">{children}</div>
      </div>
    </div>
  );
};
