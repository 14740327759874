import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import SvgCeilingLamp from '../icons/CeilingLamp';
import SvgCharger from '../icons/Charger';
import SvgDesktopMonitorApprove from '../icons/DesktopMonitorApprove';
import SvgToolsHammerHold from '../icons/ToolsHammerHold';
import SvgToolsWrenchHold from '../icons/ToolsWenchHold';

export const Services = () => {
  const data = useStaticQuery(query);
  return (
    <div id="services" className="w-full lg:pl-20">
      <div className="grid w-full grid-cols-3 my-2 xs:justify-center lg:justify-start">
        {data.page.frontmatter.services.map((service) => {
          const Icon = IconFinder(service.icon);
          return (
            <div className="py-3 text-white md:px-2 xs:w-32 md:w-48">
              <Icon
                className="mx-auto mb-2 fill-current text-primary-400"
                width="48"
              />
              <h3 className="text-base font-semibold text-center uppercase">
                {service.name}
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const IconFinder = (option) => {
  switch (option) {
    case "stekker":
      return SvgCharger;
    case "hamer":
      return SvgToolsHammerHold;
    case "computer":
      return SvgDesktopMonitorApprove;
    case "lamp":
      return SvgCeilingLamp;
    case "moersleutel":
      return SvgToolsWrenchHold;
    default:
      return null;
  }
};

export const query = graphql`
  query Services {
    page: markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
      frontmatter {
        services {
          name
          icon
        }
      }
    }
  }
`;
